import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";

import DataPrivacyPage from "../userContent/userPages/DataPrivacyPage/DataPrivacyPage";

const DataPrivacy = () => {
    useEffect(_=>{
        if(typeof window !== "undefined"){
            window.scrollTo(0,0)
        }
    },[])
    return (
        <DataPrivacyPage/>
    )
}

export default withTranslation()(DataPrivacy)